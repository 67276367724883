<template>
  <article class="menu">
    <p>
      <router-link :to="`/${$route.params.id}`">Tillbaka</router-link>
    </p>
    <h1 class="mb-0">菜單</h1>
    <h2 class="mt-0">Take away-meny</h2>

    <p class="mb-3">
      Ring och beställ:
      <a class="accent bold" href="tel:040319898">040 &mdash; 31 98 98</a>
    </p>

    <section class="menu-container">
      <menu-category
        v-for="(category, index) in this.menu"
        :key="`category-${index}`"
        :category="category"
      />
    </section>
  </article>
</template>

<script>
import MenuCategory from "../components/MenuCategory.vue";

const locations = {
  malmo: [
    {
      items: [
        {
          name: {
            en: "Wo Tip dumplings with ‘crispy bottom‘",
            sv: "Wo Tip dumplings (Fläsk) med ‘crispy bottom‘.",
            zh: "餃子"
          },
          price: 150,
          quantity: 8
        },
        {
          name: {
            en:
              "Crispy wonton snacks w. scampi, sweet and sour chili dip (5pcs)",
            sv: "Krispiga wontonsnacks, scampi, Sötsur-chilidipp (5st)",
            zh: "炸餛飩"
          },
          price: 120
        }
      ],
      name: {
        sv: "Dumplings",
        zh: "餃子"
      },
      order: 2
    },
    {
      items: [
        {
          name: {
            en: "Roast duck with rice/egg noodles",
            sv: "Grillad anka med ris/äggnudlar",
            zh: "燒鴨飯/蛋麵"
          },
          price: 170,
          quantity: 1
        },
        {
          name: {
            en: "Siu Yuk - Crispy roasted pork belly with rice/egg noodles",
            sv: "Siu Yuk - Krispigt grillat sidfläsk med ris/äggnudlar",
            zh: "燒肉飯/蛋麵"
          },
          price: 165,
          quantity: 1
        }
      ],
      name: {
        sv: "Cantonese Roast Meat",
        zh: "燒味"
      },
      order: 0
    },
    {
      name: {
        sv: "Soppa",
        zh: "湯"
      },
      order: 1
    },
    {
      items: [
        {
          name: {
            en: "Beans, high reef, long beans, oyster-fish sauce",
            sv:
              "Bönor & högrev. Långbönor, högrevsfärs, ostron-fisksås, ingår ris",
            zh: "낫떴큇흄"
          },
          price: 180
        },
        {
          name: {
            en: "Cod-loin, roasted ginger, leek, sweet soy",
            sv:
              "48° Torsk. Torskrygg, rostad ingefära, salladslök, sötsoja. Serveras med ris",
            zh: "蒸鰻魚"
          },
          price: 190
        }
      ],
      name: {
        sv: "Kökets specialare",
        zh: ""
      },
      order: 3
    },
    {
      items: [
        {
          name: {
            en: "Mapo Tofu in a vegan version",
            sv:
              "Sichuankökets favvis i en vegansk version: - Mapo Tofu! Het och kryddig tofu-gryta med sojafärs, Serveras med ris.",
            zh: "麻婆豆腐"
          },
          price: 145,
          quantity: 1
        },
        {
          name: {
            en: "Chinese broccoli, garlic, soy-oyster sauce",
            sv: "Kailan (kinesisk broccoli), vitlök, soja-ostronsås",
            zh: "時꽉窮"
          },
          price: 155,
          quantity: 1
        }
      ],
      name: {
        sv: "Veganskt",
        zh: "小吃"
      },
      order: 4
    },
    {
      items: [
        {
          name: {
            en:
              "Stir fried wheat noodles, beef, leek, beansprouts soy-oyster sauce",
            sv:
              "Biff Chow Ho Fun. Wokade vetenudlar med rostbiff, böngroddar, kinesisk salladslök.",
            zh: "干炒牛河"
          },
          price: 175,
          quantity: 1
        }
      ],
      name: {
        sv: "Nudlar",
        zh: "燒味"
      },
      order: 3
    }
  ]
};

export default {
  components: { MenuCategory },
  data: () => ({
    menu: []
  }),
  created() {
    this.id = this.$route.params.id;
    this.menu =
      locations[this.id]
        .filter(category => category.items && category.items.length > 0)
        .sort((a, b) => (a.order > b.order ? 1 : -1)) ?? [];
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
  font-weight: 700;
}
h1 + h2 {
  font-size: 1rem;
}

.text-vertical {
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.menu {
  flex-direction: column;
  align-items: flex-start;
}
</style>
